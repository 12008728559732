import { IGatsbyImageData } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

type Alignment = "left" | "right";

interface Props {
  title: string;
  titleAsDiv?: boolean;
  description: string;
  image?: { gatsbyImageData: IGatsbyImageData };
  alignment?: Alignment;
}

const CollectionHeader: React.SFC<Props> = ({
  alignment = "right",
  title,
  titleAsDiv,
  description,
  image,
}) => {
  return (
    <Container alignment={alignment} className="header">
      {image && (
        <ImageContainer alignment={alignment}>
          <Image image={image.gatsbyImageData} alt="" />
        </ImageContainer>
      )}
      <Content className="content">
        {!titleAsDiv && <TitleAsH1>{title}</TitleAsH1>}
        {titleAsDiv && <Title>{title}</Title>}
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: calc(100% + 100px);
  margin-left: -50px;
  height: 400px;
  background-color: ${({ theme }) => theme.colors.background2};
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: ${({ alignment }: { alignment: Alignment }) =>
    alignment === "right" ? "row" : "row-reverse"};
  margin-top: ${({ alignment }: { alignment: Alignment }) =>
    alignment === "right" ? 90 : 140}px;
  margin-bottom: ${({ alignment }: { alignment: Alignment }) =>
    alignment === "right" ? 0 : 140}px;
  @media (max-width: 1400px) {
    width: 100%;
    margin-left: 0;
  }
  @media (max-width: 950px) {
    height: auto;
    margin-top: 290px;
    padding-top: 250px;
    margin-bottom: 0px;
  }
  @media (max-width: 620px) {
    background-color: transparent;
    flex-direction: column;
    margin-top: 30px;
    padding-top: 0;
  }
  @media (max-width: 450px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 20px;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: -50px;
  width: 500px;
  left: ${({ alignment }: { alignment: Alignment }) =>
    alignment === "right" ? "auto" : "50px"};
  right: ${({ alignment }: { alignment: Alignment }) =>
    alignment === "right" ? "50px" : "auto"};
  @media (max-width: 1100px) {
    width: 400px;
  }
  @media (max-width: 950px) {
    width: 500px;
    top: -250px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 620px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: calc(100% - 80px);
  }
  @media (max-width: 450px) {
    width: calc(100% - 40px);
  }
`;

const Image = styled(GatsbyImage)`
  background-color: ${({ theme }) => theme.colors.grey};
  display: block;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;
  width: calc(100% - 550px);
  @media (max-width: 1100px) {
    width: calc(100% - 450px);
  }
  @media (max-width: 1000px) {
    width: calc(100% - 450px);
  }
  @media (max-width: 950px) {
    width: 100%;
    padding: 40px;
  }
  @media (max-width: 620px) {
    margin-top: -200px;
    padding-top: 240px;
    background-color: ${({ theme }) => theme.colors.background2};
  }
  @media (max-width: 450px) {
    margin-top: -150px;
    padding: 180px 20px 30px 20px;
  }
`;

const TitleAsH1 = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 950px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 950px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const Description = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.04em;
  font-weight: 400;
  line-height: 1.8em;
  white-space: pre-line;
  p {
    margin: 0;
  }
  a {
    color: ${({ theme }) => theme.colors.main};
  }
`;

export default CollectionHeader;
