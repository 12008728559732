import React from "react";
import styled from "styled-components";
import { graphql, Link as BasicLink } from "gatsby";
import CollectionHeader from "components/CollectionHeader";
import Wrapper from "components/Wrapper";
import { DatoCmsCollection, ShopifyCollection } from "graphql-typings";
import { FieldLocale, getLocalizedField } from "utils/common/locale";
import { computePath } from "utils/common/navigation";
import SEO from "components/SEO";
import { useTranslation } from "hooks/useTranslation";
import { IGatsbyImageData } from "gatsby-plugin-image";

interface Props {
  data: {
    allDatoCmsCollection: { nodes: DatoCmsCollection[] };
    allShopifyCollection: { nodes: ShopifyCollection[] };
  };
  pageContext: {
    locale: string;
    defaultLocale: string;
    allPathLocales: FieldLocale<string>[];
    pagePath: string;
  };
}

/**
 * Collections page wrapper
 * Just to allow useContext usage inside
 */
const CollectionsPage: React.SFC<Props> = ({ data, pageContext }) => {
  return <Collections data={data} pageContext={pageContext} />;
};

const Collections: React.SFC<Props> = ({ data, pageContext }) => {
  const { locale, defaultLocale, pagePath } = pageContext;
  const { formatMessage } = useTranslation();
  const datocmsCollections = data.allDatoCmsCollection.nodes;
  const shopifyCollections = data.allShopifyCollection.nodes;

  const findShopifyCollectionImage = (shopifyHandle: string) => {
    const collection = shopifyCollections.find(
      (collection: any) => collection.handle === shopifyHandle
    );
    return (
      collection &&
      (collection.image as { gatsbyImageData: IGatsbyImageData } | undefined)
    );
  };

  return (
    <Wrapper>
      <SEO
        title={formatMessage({ id: "SEO.collectionsTitle" })}
        description={formatMessage({ id: "SEO.collectionsDescription" })}
        link={[
          { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
        ]}
      />
      <Title>{formatMessage({ id: "collections.title" })}</Title>
      {datocmsCollections.map(
        (collection: DatoCmsCollection, index: number) => (
          <Link
            key={index}
            to={computePath(
              locale,
              defaultLocale,
              collection.__typename,
              // @ts-ignore
              collection._allHandleLocales
            )}
          >
            <CollectionHeader
              alignment={index % 2 === 0 ? "left" : "right"}
              title={
                getLocalizedField(
                  collection._allTitleLocales as FieldLocale<string>[],
                  locale
                )!.value
              }
              titleAsDiv
              description={
                collection._allDescriptionLocales!.find(
                  (fl) => fl!.locale === locale
                )!.valueNode!.childMarkdownRemark!.html || ""
              }
              image={findShopifyCollectionImage(
                collection.shopifyHandle as string
              )}
            />
          </Link>
        )
      )}
    </Wrapper>
  );
};

export const query = graphql`
  query collectionsQuery($locale: String) {
    allDatoCmsCollection(
      filter: { locale: { eq: $locale }, hidden: { ne: true } }
    ) {
      nodes {
        __typename
        shopifyHandle
        _allHandleLocales {
          locale
          value
        }
        _allTitleLocales {
          locale
          value
        }
        _allDescriptionLocales {
          locale
          value
          valueNode {
            childMarkdownRemark {
              html
            }
          }
        }
        composableCollection
        _allComposeHandleLocales {
          locale
          value
        }
        _allComposeCallToActionLocales {
          locale
          value
        }
      }
    }
    allShopifyCollection(sort: { fields: [handle], order: ASC }) {
      nodes {
        handle
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`;

const Title = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  padding-top: 30px;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const Link = styled(BasicLink)`
  text-decoration: none;
`;

export default CollectionsPage;
